import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
// config
import { FIREBASE_API, APP_CALLS, APP_ECLASS } from '../config-global';
// utils
import axios from '../utils/axios';
import axiosEclass from '../utils/axiosEclass';
//
import { setSession, logoutToken } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const GOOGLE_PROVIDER = new GoogleAuthProvider();

const FACEBOOK_PROVIDER = new FacebookAuthProvider();

const MICROSOFT_PROVIDER = new OAuthProvider('microsoft.com');

const TWITTER_PROVIDER = new TwitterAuthProvider();

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (fAuth) => {
        if (fAuth) {

          let rEclas;
          let mergedUserRole;
          let accessToken;
          let user;
          let communeId;
          
          if(APP_CALLS === 'true'){
            
            const response = await axios.post('/authentication', {
              strategy: 'firebase',
              access_token: fAuth.accessToken,
            });

            user = response.data.user;

            accessToken = response.data.accessToken;

            mergedUserRole = user.roles;

          }

          if(APP_ECLASS === 'true'){

            rEclas = await axiosEclass.post('/authentication', {
              strategy: 'firebase',
              access_token: fAuth.accessToken,
            });

            user = user || rEclas.data.user;

            accessToken = rEclas.data.accessToken;

            mergedUserRole = mergedUserRole ? [...mergedUserRole, ...rEclas.data.user.roles] : rEclas.data.user.roles;

            communeId = rEclas.data.user.eclass_student?.communeId || '';

          }
      
          mergedUserRole = mergedUserRole.reduce((result, obj) => {
            if (!result.some(item => item.id === obj.id)) {
              result.push(obj);
            }
            return result;
          }, []);
            
          setSession(accessToken);

          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                photoURL: user.picture,
                roles: mergedUserRole.map( rol => rol.id),
                communeId
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(() => {
    signInWithPopup(AUTH, GOOGLE_PROVIDER);
  }, []);

  const loginWithFacebook = useCallback(() => {
    signInWithPopup(AUTH, FACEBOOK_PROVIDER);
  }, []);

  const loginWithMicrosoft = useCallback(() => {
    signInWithPopup(AUTH, MICROSOFT_PROVIDER);
  }, []);

  const loginWithTwitter = useCallback(() => {
    signInWithPopup(AUTH, TWITTER_PROVIDER);
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    await createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);

      await setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
      });
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    logoutToken();
    signOut(AUTH);
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'firebase',
      login,
      loginWithGoogle,
      loginWithFacebook,
      loginWithMicrosoft,
      loginWithTwitter,
      register,
      logout,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      loginWithFacebook,
      loginWithMicrosoft,
      loginWithGoogle,
      loginWithTwitter,
      register,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
