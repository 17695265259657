// @mui
import { Stack, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();
  
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5}} 
        alignItems="center"
        justifyContent="center">
          
        <Logo sx={{ maxWidth: 200 }}/>

        <Typography variant="h4">Ingresar con</Typography>

      </Stack>

      <AuthWithSocial />
    </LoginLayout>
  );
}
