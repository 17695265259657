import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));

// DASHBOARD: GENERAL
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));
export const GeneralAnalyticsPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralAnalyticsPage'))
);
export const GeneralBankingPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralBankingPage'))
);
export const GeneralConfiguration = Loadable(
  lazy(() => import('../pages/dashboard/GeneralConfiguration'))
);

// DASHBOARD: USER
export const UserProfilePage = Loadable(lazy(() => import('../pages/dashboard/UserProfilePage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));

// ADMIN: CALLS (CONVOCATORIAS)
export const GeneralCallsPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralCallsPage'))
);
export const CallsNewPage = Loadable(
  lazy(() => import('../pages/dashboard/CallsNewPage'))
)
export const CallApplicationsPage = Loadable(
  lazy(() => import('../pages/dashboard/CallApplicationsPage'))
);
export const CallFormPage = Loadable(
  lazy(() => import('../pages/dashboard/CallFormPage'))
);
export const CallEvaluationPage = Loadable(
  lazy(() => import('../pages/dashboard/CallEvaluationPage'))
);
export const CallConfigPage = Loadable(
  lazy(() => import('../pages/dashboard/CallConfigPage'))
);
export const PostulacionDetailPage = Loadable(
  lazy(() => import('../pages/dashboard/PostulacionDetailPage'))
);

// ADMIN: E-CLASS (CAPACITACIONES)
export const GeneralEClassPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralEClassPage'))
);
export const EClassDashboardPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassDashboardPage'))
);
export const EClassStudentsListPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassStudentsListPage'))
);
export const EClassStudentsNewPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassStudentsNewPage'))
);
export const EClassStudentsNewMassivePage = Loadable(
  lazy(() => import('../pages/dashboard/EClassStudentsNewMassivePage'))
);
export const EClassStudentsEditPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassStudentsEditPage'))
);
export const EClassModulesPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassModulesPage'))
);
export const EClassModulesNewPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassModulesNewPage'))
);
export const EClassModulesEditPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassModulesEditPage'))
);
export const EClassModulesDashboardPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassModulesDashboardPage'))
);
export const EClassEvaluationsPage = Loadable(
  lazy(() => import('../pages/dashboard/EClassEvaluationsPage'))
);

// POSTULANTE
export const PostulanteConvocatoriasPage = Loadable(lazy(() => import('../pages/postulante/PostulanteConvocatoriasPage')));
export const PostulanteFormularioPage = Loadable(lazy(() => import('../pages/postulante/PostulanteFormularioPage')));
export const PostulanteMisPostulacionesPage = Loadable(lazy(() => import('../pages/postulante/PostulanteMisPostulacionesPage')));
export const PostulanteFormularioViewPage = Loadable(lazy(() => import('../pages/postulante/PostulanteFormularioViewPage')));

// ECLASS: STUDENTS
export const StudentsEclassPage = Loadable(lazy(() => import('../pages/student/StudentsEclassPage')));
export const StudentsEclassModulesPage = Loadable(lazy(() => import('../pages/student/StudentsEclassModulesPage')));
export const StudentsModulePage = Loadable(lazy(() => import('../pages/student/StudentsModulePage')));

// ECLASS: EVALUATOR
export const EvaluatorEclassPage = Loadable(lazy(() => import('../pages/evaluator/EvaluatorEclassPage')));
export const EvaluatorEclassModulesPage = Loadable(lazy(() => import('../pages/evaluator/EvaluatorEclassModulesPage')));
export const StudentModuleEvaluatePage = Loadable(lazy(() => import('../pages/evaluator/StudentModuleEvaluatePage')));

// ECLASS: ADMIN
export const EClassNewPage = Loadable(lazy(() => import('../pages/dashboard/EClassNewPage')));

// EVALUATION
export const EvaluationApplicationAdminPage = Loadable(lazy(() => import('../pages/dashboard/EvaluationApplicationAdminPage')));

// EVALUATOR
export const EvaluatorMainPage = Loadable(lazy(() => import('../pages/evaluator/EvaluatorMainPage')));
export const EvaluatorCallPage = Loadable(lazy(() => import('../pages/evaluator/EvaluatorCallPage')));
export const EvaluatorApplicationPage = Loadable(lazy(() => import('../pages/evaluator/EvaluatorApplicationPage')));