// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_POSTULANTE = '/postulaciones';
const ROOTS_STUDENTS = '/capacitaciones';
const ROOTS_EVALUATOR = '/evaluaciones';
const ROOTS_ECLASS_EVALUATOR = '/evaluar';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/convocatorias'),
    analytics: path(ROOTS_DASHBOARD, '/capacitaciones'),
    banking: path(ROOTS_DASHBOARD, '/usuarios'),
    booking: path(ROOTS_DASHBOARD, '/configuracion'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  convocatorias: {
    general: path(ROOTS_DASHBOARD, '/convocatorias/general'),
    nueva: path(ROOTS_DASHBOARD, '/convocatorias/nueva'),
    postulaciones: (id) => path(ROOTS_DASHBOARD, `/convocatorias/${id}/postulaciones`),
    formulario: (id) => path(ROOTS_DASHBOARD, `/convocatorias/${id}/formulario`),
    evaluaciones: (id) => path(ROOTS_DASHBOARD, `/convocatorias/${id}/evaluaciones`),
    configuracion: (id) => path(ROOTS_DASHBOARD, `/convocatorias/${id}/configuracion`),
    postulacion: (id, applicationId) => path(ROOTS_DASHBOARD, `/convocatorias/${id}/postulaciones/${applicationId}`),
    //
    evaluar: (id, applicationId) => path(ROOTS_DASHBOARD, `/convocatorias/${id}/evaluaciones/${applicationId}/evaluar`),
  },
  eclass: {
    general: path(ROOTS_DASHBOARD, '/capacitaciones'),
    generalConfig: path(ROOTS_DASHBOARD, '/configuracion'),
    new: path(ROOTS_DASHBOARD, '/capacitaciones/nueva'),
    dashboard: (courseId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/reporteria`),
    students: (courseId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/estudiantes`),
    modules: (courseId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/modulos`),
    evaluations: (courseId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/evaluaciones`),
    config: (courseId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/configuracion`),
    // students
    studentDetail: (courseId, studentId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/estudiantes/${studentId}`),
    newStudent: (courseId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/estudiantes/nuevo`),
    massive: (courseId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/estudiantes/nuevo/masivo`),
    // modules
    moduleDetail: (courseId, studentId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/modulos/${studentId}`),
    newModule: (courseId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/modulos/nuevo`),
    editModule: (courseId, moduleId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/modulos/${moduleId}/edit`),
    modulesDashboard: (courseId, moduleId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/modulos/${moduleId}/dashboard`),
    // evaluacion
    viewEvaluate: (courseId, studentsClassApplicationId) => path(ROOTS_DASHBOARD, `/capacitaciones/${courseId}/evaluaciones/${studentsClassApplicationId}`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_POSTULANTE = {
  root: ROOTS_POSTULANTE,
  myApplications: {
    all: path(ROOTS_POSTULANTE, '/mis-postulaciones'),
    view: (id) => path(ROOTS_POSTULANTE, `/mis-postulaciones/${id}`),
  },
  calls: path(ROOTS_POSTULANTE, '/convocatorias'),
  apply: (id) => path(ROOTS_POSTULANTE, `/convocatorias/${id}/postular`),
  user: {
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
  }
}

export const PATH_STUDENTS = {
  root: ROOTS_STUDENTS,
  class: {
    view: (courseId) => path(ROOTS_STUDENTS, `/${courseId}/modulos`),
  },
  module: {
    view: (courseId, moduleId) => path(ROOTS_STUDENTS, `/${courseId}/modulos/${moduleId}`),
  },
}

export const PATH_EVALUATOR_ECLASS = {
  root: path(ROOTS_ECLASS_EVALUATOR, ''),
  class: {
    view: (courseId) => path(ROOTS_ECLASS_EVALUATOR, `/${courseId}/modulos`),
  },
  module:  {
    view: (courseId, moduleId) => path(ROOTS_POSTULANTE, `/${courseId}/modulos/${moduleId}`),
    evaluate: (courseId, studentsClassApplicationId) => path(ROOTS_ECLASS_EVALUATOR, `/${courseId}/modulos/${studentsClassApplicationId}/evaluar`),
  },
  // (id, applicationId) => path(ROOTS_DASHBOARD, `/convocatorias/${id}/postulaciones/${applicationId}`),
}

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';

// POSTULANTE
export const PATH_EVALUATOR = {
  root: ROOTS_EVALUATOR,
  evaluation: {
    calls: path(ROOTS_EVALUATOR, '/evaluar'),
    applications: (callId, evaluatorId) => path(ROOTS_EVALUATOR, `/evaluar/${callId}/${evaluatorId}`),
    viewApply: (callId, evaluatorId, applyId) => path(ROOTS_EVALUATOR, `/evaluar/${callId}/${evaluatorId}/${applyId}`),
  },
}