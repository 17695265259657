import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import PostulanteLayout from '../layouts/postulante';
import EvaluatorLayout from '../layouts/evaluator';
import EclassEvaluatorLayout from '../layouts/eclassEvaluator'
import RouteLayouts from '../layouts/RouteLayouts';
import RoleBasedGuard from '../auth/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN, PATH_AFTER_LOGIN_POSTULACIONES, PATH_AFTER_LOGIN_EVALUATOR, PATH_AFTER_LOGIN_ECLASS, APP_CALLS, APP_ECLASS } from '../config-global';
//
import {
  // Auth
  LoginPage,
  // Dashboard: General
  GeneralAppPage,
  GeneralBankingPage,
  GeneralConfiguration,
  // Dasboard: Calls (Convocatoria)
  GeneralCallsPage,
  CallsNewPage,
  CallApplicationsPage,
  CallFormPage,
  CallEvaluationPage,
  CallConfigPage,
  PostulacionDetailPage,
  // Dashboard: EClass (Capacitaciones)
  GeneralEClassPage,
  EClassNewPage,
  EClassDashboardPage,
  EClassStudentsListPage,
  EClassStudentsNewPage,
  EClassStudentsNewMassivePage,
  EClassStudentsEditPage,
  EClassModulesPage,
  EClassModulesNewPage,
  EClassModulesEditPage,
  EClassModulesDashboardPage,
  EClassEvaluationsPage,
  // EVALUATOR
  EvaluatorEclassPage,
  EvaluatorEclassModulesPage,
  StudentModuleEvaluatePage,
  // STUDENT
  StudentsEclassPage,
  StudentsEclassModulesPage,
  StudentsModulePage,
  // Dashboard: User
  UserProfilePage,
  // EVALUATOR
  EvaluatorMainPage,
  EvaluatorCallPage,
  EvaluatorApplicationPage,
  // Postulante
  PostulanteConvocatoriasPage,
  PostulanteFormularioPage,
  PostulanteMisPostulacionesPage,
  PostulanteFormularioViewPage,
  // Evaluation
  EvaluationApplicationAdminPage,
  //
  Page500,
  Page403,
  Page404,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {

  let routes = [
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        }
      ],
    },
    // root
    {
      path: '/',
      element: (
        <AuthGuard>
            <RouteLayouts />
        </AuthGuard>
      )
    },
    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['admin']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: (APP_CALLS === 'true' && <Navigate to={PATH_AFTER_LOGIN} replace />) || (APP_ECLASS === 'true' && <Navigate to={PATH_AFTER_LOGIN_ECLASS} replace />), index: true },
        { path: 'app', element: <GeneralAppPage /> },
        { path: 'convocatorias', 
          module: 'CALLS',
          children: [
            { element: <Navigate to="/dashboard/convocatorias/general" replace />, index: true },
            { path: 'general', element: <GeneralCallsPage /> },
            { path: 'nueva', element: <CallsNewPage /> },
            { path: ':id/postulaciones', element: <CallApplicationsPage /> },
            { path: ':id/postulaciones/:applicationId', element: <PostulacionDetailPage /> },
            { path: ':id/formulario', element: <CallFormPage /> },
            { path: ':id/evaluaciones', element: <CallEvaluationPage /> },
            { path: ':id/evaluaciones/:applicationId/evaluar', element: <EvaluationApplicationAdminPage /> },
            { path: ':id/configuracion', element: <CallConfigPage /> },
          ],
        },
        { path: 'capacitaciones', 
          module: 'ECLASS',
          children: [
            { element: <Navigate to="/dashboard/capacitaciones/general" replace />, index: true },
            { path: 'general', element: <GeneralEClassPage /> },
            { path: 'nueva', element: <EClassNewPage /> },
            { path: ':courseId/reporteria', element: <EClassDashboardPage /> },
            // students
            { path: ':courseId/estudiantes', element: <EClassStudentsListPage /> },
            { path: ':courseId/estudiantes/nuevo', element: <EClassStudentsNewPage /> },
            { path: ':courseId/estudiantes/nuevo/masivo', element: <EClassStudentsNewMassivePage /> },
            { path: ':courseId/estudiantes/:studentId', element: <EClassStudentsEditPage /> },
            // modules
            { path: ':courseId/modulos', element: <EClassModulesPage /> },
            { path: ':courseId/modulos/nuevo', element: <EClassModulesNewPage /> },
            { path: ':courseId/modulos/:moduleId/edit', element: <EClassModulesEditPage /> },
            { path: ':courseId/modulos/:moduleId/dashboard', element: <EClassModulesDashboardPage /> },
            { path: ':courseId/evaluaciones', element: <EClassEvaluationsPage /> },
            { path: ':courseId/evaluaciones/:studentsClassApplicationId', element: <StudentModuleEvaluatePage /> },
            { path: ':courseId/configuracion', element: <CallConfigPage /> },
          ],
        } ,
        { path: 'usuarios', element: <GeneralBankingPage /> },
        { path: 'configuracion', element: <GeneralConfiguration /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfilePage /> },
          ],
        }
      ],
    },
    // Evaluador
    // Convocatorias
    {
      path: 'evaluaciones',
      module: 'CALLS',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['evaluator']}>
            <EvaluatorLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_EVALUATOR} replace />, index: true },
        { path: 'evaluar', element: <EvaluatorMainPage /> },
        { path: 'evaluar/:id/:evaluatorId',
          children: [
            { element: <EvaluatorCallPage />, index: true },
            { path: ':applicationId', element: <EvaluatorApplicationPage /> },
          ],
      },
      ],
    },
    // Postulante
    // Convocatorias
    {
      path: 'postulaciones',
      module: 'CALLS',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['user']}>
            <PostulanteLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_POSTULACIONES} replace />, index: true },
        { path: 'mis-postulaciones',
          children: [
            { element: <PostulanteMisPostulacionesPage />, index: true },
            { path: ':id', element: <PostulanteFormularioViewPage /> },
          ]
        },
        { path: 'convocatorias',
          children: [
            { element: <PostulanteConvocatoriasPage />, index: true }, 
            { path: ':id/postular', element: <PostulanteFormularioPage /> },
          ],
         },
      ],
    },
    // ECLASS Estudiate
    {
      path: 'capacitaciones',
      module: 'ECLASS',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['student']}>
            <PostulanteLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <StudentsEclassPage />, index: true },
        { path: ':courseId/modulos', element: <StudentsEclassModulesPage /> },
        { path: ':courseId/modulos/:moduleId', element: <StudentsModulePage /> },
      ],
    },
    // ECLASS Evaluador / Mentor
    {
      path: 'evaluar',
      module: 'ECLASS',
      element: (
        <AuthGuard>
          <RoleBasedGuard hasContent roles={['eclass-evaluator']}>
            <EclassEvaluatorLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <EvaluatorEclassPage />, index: true },
        { path: ':courseId/modulos', element: <EvaluatorEclassModulesPage /> },
        { path: ':courseId/modulos/:studentsClassApplicationId/evaluar', element: <StudentModuleEvaluatePage /> },
      ],
    },
    {
      element: (
        <AuthGuard>
          <CompactLayout />
        </AuthGuard>
      ),
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]

  routes = routes.filter(i => i.module ? (APP_ECLASS === 'true' && i.module === 'ECLASS') || (APP_CALLS === 'true' && i.module === 'CALLS') : true ); 

  routes = filterCalls(routes);

  return useRoutes( routes );
}

function filterCalls ( routes ){
  routes.forEach( (route) => {
    if(route.path === 'dashboard'){
      route.children = route.children.filter(r => r.module ? (APP_CALLS === 'true' && r.module === 'CALLS' || APP_ECLASS === 'true' && r.module === 'ECLASS') : true);
    }
  })

  return routes;
}