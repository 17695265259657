// routes
import { PATH_DASHBOARD, PATH_POSTULANTE, PATH_STUDENTS, PATH_EVALUATOR_ECLASS, PATH_EVALUATOR } from '../../routes/paths';
// Config
import { APP_ECLASS, APP_CALLS } from '../../config-global';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};
export const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'administración',
    items: [
      // { title: 'general', path: '#', icon: ICONS.dashboard, disabled: true },
      { title: 'convocatorias', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce, module: 'CALLS' },
      { title: 'capacitaciones', path: PATH_DASHBOARD.eclass.general, icon: <Iconify icon="healthicons:i-training-class"/>, module: 'ECLASS' },
      { title: 'usuarios', path: '#', icon:  <Iconify icon="eva:person-fill"/>, disabled: true },
      { title: 'configuración', path: PATH_DASHBOARD.eclass.generalConfig, icon: <Iconify icon="eva:settings-2-outline"/> }
    ].filter(i => i.module ? (APP_ECLASS === 'true' && i.module === 'ECLASS') || (APP_CALLS === 'true' && i.module === 'CALLS') : true),
  }
];

export const navEvaluador = [
  // EVALUADORES
  // ----------------------------------------------------------------------
  {
    subheader: 'evaluación',
    items: [
      { title: 'evaluar', path: PATH_EVALUATOR.evaluation.calls, icon: ICONS.dashboard },
      { title: 'evaluar', path: PATH_EVALUATOR_ECLASS.root, icon: <Iconify icon="carbon:task-approved"/>, module: 'ECLASS' }
    ].filter(i => i.module ? (APP_ECLASS === 'true' && i.module === 'ECLASS') : true),
  }
];

export const navPostulaciones = [
  // POSTULACIONES
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Convocatorias', path: PATH_POSTULANTE.calls, icon: ICONS.blog, module: 'CALLS' },
      { title: 'Mis Postulaciones', path: PATH_POSTULANTE.myApplications.all, icon: ICONS.file, module: 'CALLS' }
    ].filter(i => i.module ? (APP_CALLS === 'true' && i.module === 'CALLS') : true),
  }
];

// ----------------------------------------------------------------------
// ECLASS
// ----------------------------------------------------------------------

// ADMIN HORIZONTAL BAR
// ----------------------------------------------------------------------
export const navAdminEClass = (courseId) => [
  {
    items: [
      { title: 'reporteria', path: PATH_DASHBOARD.eclass.dashboard(courseId), icon: ICONS.dashboard },
      { title: 'estudiantes', path: PATH_DASHBOARD.eclass.students(courseId), icon: <Iconify icon="octicon:mortar-board-16"/> },
      { title: 'módulos', path: PATH_DASHBOARD.eclass.modules(courseId), icon: <Iconify icon="mdi:teacher"/> },
      { title: 'evaluadores', path: PATH_DASHBOARD.eclass.evaluations(courseId), icon: <Iconify icon="carbon:task-approved"/> },
      // { title: 'configuración', path: PATH_DASHBOARD.eclass.config(courseId), icon: <Iconify icon="eva:settings-2-outline"/> }
    ],
  }
];

// EVALUACIONES
// ----------------------------------------------------------------------
export const navEclassEvaluator = [
  {
    subheader: '',
    items: [
      { title: 'evaluar', path: PATH_EVALUATOR.evaluation.calls, icon: ICONS.dashboard },
      { title: 'evaluar', path: PATH_EVALUATOR_ECLASS.root, icon: <Iconify icon="carbon:task-approved"/>, module: 'ECLASS' }
    ].filter(i => i.module ? (APP_ECLASS === 'true' && i.module === 'ECLASS') : true),
  }
];


// CAPACITACIONES
// ----------------------------------------------------------------------
export const navEclass = (roles) =>  roles.includes('student') ? [
  {
    subheader: '',
    items: [
      { title: 'Capacitaciones', path: PATH_STUDENTS.root, icon: <Iconify icon="mdi:teacher"/>, module: 'ECLASS' }
    ],
  }
].filter(i => i.module ? (APP_ECLASS === 'true' && i.module === 'ECLASS') : true) : [];
