import { Navigate } from 'react-router-dom';
// 
import { useAuthContext } from '../auth/useAuthContext';
import { APP_CALLS, APP_ECLASS } from '../config-global';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

export default function RouteLayouts() {
    const { user, isAuthenticated } = useAuthContext();

    if (isAuthenticated) {
        if(user.roles.includes('admin')){
            return <Navigate to="/dashboard" />;
        }
    
        if(user.roles.includes('evaluator')){
            return <Navigate to="/evaluaciones" />;
        }

        if(user.roles.includes('eclass-evaluator')){
            return <Navigate to="/evaluar" />;
        }
    
        if(user.roles.includes('user') || user.roles.includes('student')){
            return (APP_CALLS === 'true' && <Navigate to="/postulaciones" />) || (APP_ECLASS === 'true' && <Navigate to="/capacitaciones" />);
        }
    }

}
