// @mui
import { Divider, IconButton, Stack, Typography, Button } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function AuthWithSocial() {
  const { loginWithGoogle, loginWithFacebook, loginWithMicrosoft, login } = useAuthContext();

  const handleGoogleLogin = async () => {
    try {
      if (loginWithGoogle) {
        loginWithGoogle();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFacebookLogin = async () => {
    try {
      if (loginWithFacebook) {
        loginWithFacebook();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMicrosoftLogin = async () => {
    try {
      if (loginWithMicrosoft) {
        loginWithMicrosoft();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      {/* <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider> */}

      <Stack justifyContent="center" spacing={2}>
        <Button
          variant="outlined"
          color="inherit"
          size="large"
          onClick={handleGoogleLogin}
          startIcon={<Iconify icon="logos:google-icon" />}
        >
          Google
        </Button>

        <Button
          variant="outlined"
          color="inherit"
          size="large"
          onClick={handleFacebookLogin}
          startIcon={<Iconify icon="logos:facebook" />}
        >
          Facebook
        </Button>

        <Button
          variant="outlined"
          color="inherit"
          size="large"
          onClick={handleMicrosoftLogin}
          startIcon={<Iconify icon="logos:microsoft-icon" />}
        >
          Microsoft
        </Button>
    
      </Stack>
    </div>
  );
}
